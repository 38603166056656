$(document).on("turbolinks:load", function () {
  if ($("#custom-settings-btn").is("*")) {
    var custom_tab = $("#custom-settings-tab");

    $("#custom-settings-btn").on("click", function (event) {
      event.preventDefault();
      if (custom_tab.width() == 0) {
        custom_tab.removeClass("closed");
        custom_tab.addClass("open toggled");
      } else {
        custom_tab.removeClass("open toggled");
        custom_tab.addClass("closed");
      }
    });
  }

  $(document).on("click", ".dropdown-menu .accordion", function (e) {
    e.stopPropagation();
  });
});
