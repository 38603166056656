$(document).on("turbolinks:load", function () {
  if ($("#js-boarding-calendar").is("*")) {
    var date = $("#js-boarding-calendar").data('date');
    var location = $('#location_id').val();
    var type = $("#js-boarding-calendar").data('display-type');
    loadCalendar(date, location, type);
  }
});

function loadCalendar(date, location, type) {
  $.ajax({
    url: "/business/calendars/render_boarding_calendar",
    type: "GET",
    data: { date: date, location_id: location, type: type },
    dataType: "script",
    success: () => {
      setTimeout( function(){
        $('.js-week-button').on('click', function(event){
          var newDate = $(this).data('date')
          var location = $('#location_id').val();
          var type = $("#js-boarding-calendar").data('display-type');
          loadCalendar(newDate, location, type);
          event.preventDefault();
        });

        $('button.js-pick-week-button').flatpickr({
          onChange: function(selectedDates, dateStr, instance) {
            if(selectedDates.length == 1) {
              var location = $('#location_id').val();
              var type = $("#js-boarding-calendar").data('display-type');
              loadCalendar(selectedDates[0], location, type);
            }
          }
        });
      }, 500);
    },
  });
}
