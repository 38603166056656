import { DirectUpload } from "@rails/activestorage";
import { random } from "lodash";

function makeEvent(eventName, detail) {
  let params = { bubbles: true, cancelable: true, detail: detail };

  if (typeof window.CustomEvent === "function") {
    return new CustomEvent(eventName, params);
  } else {
    // IE 11 support
    // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      eventName,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }
}

export class AttachmentUpload {
  constructor(attachment, element) {
    this.attachment = attachment;
    this.element = element;
    this.directUpload = new DirectUpload(
      attachment.file,
      this.directUploadUrl,
      this
    );
  }

  start() {
    this.directUpload.create(this.directUploadDidComplete.bind(this));
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", (event) => {
      const progress = (event.loaded / event.total) * 100;
      this.attachment.setUploadProgress(progress);
    });
  }

  directUploadDidComplete(error, attributes) {
    this.element.dispatchEvent(
      makeEvent("trix-upload-complete", {
        target: this.element,
        error: error,
        attributes: attributes,
      })
    );
    if (error) {
      // TODO: remove this code and replace the catch we do in
      // application.js
      throw new Error(`Direct upload failed: ${error}`);
    } else {
      this.attachment.setAttributes({
        sgid: attributes.attachable_sgid,
        url: this.createBlobUrl(attributes.signed_id, attributes.filename),
      });
    }
  }

  createBlobUrl(signedId, filename) {
    return this.blobUrlTemplate
      .replace(":signed_id", signedId)
      .replace(":filename", encodeURIComponent(filename));
  }

  get directUploadUrl() {
    return this.element.dataset.directUploadUrl;
  }

  get blobUrlTemplate() {
    return this.element.dataset.blobUrlTemplate;
  }
}

// From: https://github.com/basecamp/trix
//
// # Observing Editor Changes
//
// The <trix-editor> element emits several events which you can use to observe
// and respond to changes in editor state.
//
// trix-before-initialize - fires when the <trix-editor> element is attached to
// the DOM just before Trix installs its editor object. If you need to use a
// custom Trix configuration you can change Trix.config here.
//
// trix-initialize - fires when the <trix-editor> element is attached to the DOM
// and its editor object is ready for use.
//
// trix-change - fires whenever the editor’s contents have changed.
//
// trix-paste - fires whenever text is pasted into the editor. The paste property
// on the event contains the pasted string or html, and the range of the inserted
// text.
//
// trix-selection-change - fires any time the selected range changes in the
// editor.
//
// trix-focus and trix-blur - fire when the editor gains or loses focus,
// respectively.
//
// trix-file-accept - fires when a file is dropped or inserted into the editor.
// You can access the DOM File object through the file property on the event. Call
// preventDefault on the event to prevent attaching the file to the document.
//
// trix-attachment-add - fires after an attachment is added to the document. You
// can access the Trix attachment object through the attachment property on the
// event. If the attachment object has a file property, you should store this file
// remotely and set the attachment’s URL attribute. See the attachment example for
// detailed information.
//
// trix-attachment-remove - fires when an attachment is removed from the
// document. You can access the Trix attachment object through the attachment
// property on the event. You may wish to use this event to clean up remotely
// stored files.

var events = [
  "trix-before-initialize",
  "trix-initialize",
  "trix-change ",
  "trix-paste",
  "trix-selection-change",
  "trix-blur",
  "trix-focus",
  "trix-attachment-add",
  "trix-file-accept",
  "trix-attachment-remove",
];

function generateSetAndReturnRandomizedCacheKey(cacheId) {
  let randomizedValue = Math.random();

  // This handles randomized collisions from existing cache keys
  while (true) {
    let existingCache =
      localStorage["message-cache-current-randomized-cache-key" + cacheId];

    if (localStorage["message-cache-current-randomized-cache-key" + cacheId]) {
      randomizedValue = Math.random();
    } else {
      break;
    }
  }

  localStorage["message-cache-current-randomized-cache-key" + cacheId] =
    randomizedValue;

  return randomizedValue;
}

function getOrInitializeCurrentRandomizedCacheKey(cacheId) {
  let currentValue =
    localStorage["message-cache-current-randomized-cache-key" + cacheId];

  if (!currentValue || currentValue == null || currentValue == undefined) {
    // console.log("Generating a new randomized cache key!");
    currentValue = generateSetAndReturnRandomizedCacheKey(cacheId);
  }

  return currentValue;
}

function storeInCache(cacheId, randomizedCacheKey, value) {
  // // console.log( `STORING IN CACHE!!! cacheId: ${cacheId} randomized: ${randomizedCacheKey}`);
  localStorage["messages-cache-" + cacheId + randomizedCacheKey] = value;
}

function getCache(cacheId, randomizedCacheKey) {
  // console.log( `++ Getting cache ++ cacheId: ${cacheId}  randomized: ${randomizedCacheKey}`);

  return localStorage["messages-cache-" + cacheId + randomizedCacheKey];
}

function clearCache(cacheId, randomizedCacheKey) {
  localStorage.removeItem("messages-cache-" + cacheId + randomizedCacheKey);
  // console.log( `== Clearing cache == cacheId: ${cacheId}  randomized: ${randomizedCacheKey}`);

  // console.log( "You know, I better just check this value out after removing it...", localStorage["messages-cache-" + cacheId + randomizedCacheKey]);
  clearCurrentRandomizedCacheKey(cacheId);
}

function clearCurrentRandomizedCacheKey(cacheId) {
  localStorage.removeItem(
    "message-cache-current-randomized-cache-key" + cacheId
  );
}

document.addEventListener("trix-initialize", (event) => {
  // console.log("trix-initialize, restoring editor state if present");
  const { target } = event;
  let cacheId = target.dataset.busypawsTrixCacheId;

  if (!cacheId || cacheId == null || cacheId == undefined) {
    return;
  }

  let currentRandomizedCacheKey =
    getOrInitializeCurrentRandomizedCacheKey(cacheId);

  // console.log("calling get cache from initialize");
  let cachedValue = getCache(cacheId, currentRandomizedCacheKey);

  if (cachedValue) {
    // Restore editor state from local storage
    target.editor.loadJSON(JSON.parse(cachedValue));
    // console.log("Restored editor state");
  } else {
    // Calling 'storeInCache' here ensures that we have a valid value. When we
    // do not have a valid value, it will be because we will have sent the
    // email/message. Then we will write logic in the change event not to write
    // to the cache.
    storeInCache(
      cacheId,
      currentRandomizedCacheKey,
      JSON.stringify(target.editor)
    );
  }

  target.dataset.randomized = currentRandomizedCacheKey;
  // console.log("target.dataset.randomized", target.dataset.randomized);

  // Register a listener to **clear**:
  //
  // A) the cached value from storage so we don't store keep storing entire
  // emails in in the user's localSession permanently, forever
  // B) the randomizedCacheKey for this message thread so we acquire a new,
  // empty cache when the user types.
  event.target.inputElement.form.addEventListener("submit", function (event) {
    clearCache(cacheId, currentRandomizedCacheKey);
  });

  target.dataset.finishedInitializing = "true";

  setInterval(
    function () {
      let cacheIdRandomized = target.dataset.randomized;

      if (!cacheId || cacheId == null || cacheId == undefined) {
        return;
      }

      // The trix-change event can fire during trix-initialize
      if (target.dataset.finishedInitializing != "true") {
        return;
      }

      let cachedValue = getCache(cacheId, cacheIdRandomized);
      if (!cachedValue) {
        // console.log("Detected it with interval...");
        $(".js-show-already-sent-warning").removeClass("d-none");
      }
    },

    2000
  );
});

document.addEventListener("trix-change", (event) => {
  const { target } = event;

  let cacheId = target.dataset.busypawsTrixCacheId;
  let cacheIdRandomized = target.dataset.randomized;

  if (!cacheId || cacheId == null || cacheId == undefined) {
    return;
  }

  // The trix-change event can fire during trix-initialize
  if (target.dataset.finishedInitializing != "true") {
    return;
  }

  // console.log("Calling getCache from trix-change", cacheId, cacheIdRandomized);

  let cachedValue = getCache(cacheId, cacheIdRandomized);

  if (cachedValue) {
    // console.log("Cached value was found...", cachedValue);
    // Save editor state to local storage
    storeInCache(cacheId, cacheIdRandomized, JSON.stringify(target.editor));
  } else {
    clearCache(cacheId, cacheIdRandomized); // clear the old one, just to be sure
    $(".js-show-already-sent-warning").removeClass("d-none");
  }
});

document.addEventListener("trix-attachment-add", (event) => {
  // console.log("trix-attachment-add uploading");
  const { attachment, target } = event;

  if (attachment.file) {
    const form = event.target.inputElement.form;

    form.querySelectorAll("[type='submit']").forEach((item) => {
      item.disabled = true;
    });

    const upload = new AttachmentUpload(attachment, target);
    // This event is guaranteed to fire for both error and success
    target.addEventListener(
      "trix-upload-complete",
      (event) => {
        event.target.inputElement.form
          .querySelectorAll("[type='submit']")
          .forEach((item) => {
            item.disabled = false;
          });
      },
      { once: true }
    );
    upload.start();
  }
});
