onmount = require("onmount");

$(document).on("turbolinks:load", function () {
  // Delete form fields
  function delete_fields() {
    $('body').on('click', '.js-delete-field', function (event) {
      event.preventDefault();
      var delete_link = $(this);
      var container = delete_link.closest(".js-delete-target");

      container.slideUp("fast", function () {
        if (
          !container.find("input, select").val() ||
          container.hasClass("new-record")
        ) {
          container.remove();
        } else {
          container.find("input, select").prop("disabled", true);
          container.find(".destroy-check").val(true);
          container.find(".destroy-check").prop("disabled", false);
          container.find("input[name$='[id]']").prop("disabled", false);
        }
      });
    });
  }

  delete_fields();

  // Link to add multiple nested form fields
  $('body').on('click', '[data-form-prepend]', function(_e) {
    var obj = $($(this).attr("data-form-prepend"));
    var new_record_key = new Date().getTime();

    obj.find("input, select, textarea, label").each(function () {
      $(this).attr("name", function () {
        if (this.tagName == "LABEL" && this.getAttribute("for") !== null) {
          this.setAttribute(
            "for",
            this.getAttribute("for").replace("new_record", new_record_key)
          );
        } else if($(this).attr("name") !== undefined) {
          return $(this).attr("name").replace("new_record", new_record_key);
        }
      });
    });

    obj.find("[id$=new_record]").each(function () {
      $(this).attr("id", function () {
        return $(this).attr("id").replace("new_record", new_record_key);
      });
    });

    obj.find("[for$=new_record]").each(function () {
      $(this).attr("for", function () {
        return $(this).attr("for").replace("new_record", new_record_key);
      });
    });

    if ($(this).data("insertBefore") != undefined) {
      insertElem = $($(this).data("insertBefore"))[0];
      obj.insertBefore(insertElem);
    } else {
      obj.insertBefore(this);
    }

    delete_fields();
    $(window).trigger("load.bs.select.data-api");
    $("[data-form-prepend]").trigger("after-load");
    onmount();

    return false;
  });
});
