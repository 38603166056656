function queryDOMForDuplicatedIds() {
  let ids = {};
  let found_one_duplicate = false;
  document
    .querySelectorAll("input:not([multiple='multiple']), select, textarea")
    .forEach((item) => {
      if (found_one_duplicate) return;

      if (item.id && ids[item.id]) {
        let message = `WARNING! Found at least two elements with the same ids! ${item.id}`;
        console.log(message, item.id, item, ids[item.id]);
        // document.body.innerHTML = message;
        found_one_duplicate = true;
      } else if (item.id) {
        ids[item.id] = item;
      }
    });
}

$(document).on("turbolinks:load", function () {
  queryDOMForDuplicatedIds();
});

setInterval(queryDOMForDuplicatedIds, 10000);
