import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    window.currentTypheadQuery = ''

    $('#line-item-typeahead-search-form').submit(function (event) {
      event.preventDefault();
    });
  }

  addLineItemToInvoiceContainerBtn(event){
    event.preventDefault();

    var elemTarget = $(event.target);

    if(elemTarget.hasClass('dropdown-item') || elemTarget.hasClass('filter-option-inner-inner')){
      return;
    }

    var addToCartBtn = elemTarget.find('.js-add-to-cart:visible');

    if(addToCartBtn.length == 0){
      var addToCartBtn = elemTarget.closest('.ob-service-button').find('.js-add-to-cart:visible');
    }

    this.addLineItemToInvoice(addToCartBtn.data());

    event.preventDefault();
    event.stopPropagation();
  }

  addLineItemToInvoiceBtn(event){
    var data = $(event.target).data();

    $(event.target).prop('disabled', 'disabled');

    this.addLineItemToInvoice(data);

    $(event.target).prop('disabled', false);

    event.preventDefault();
    event.stopPropagation();
  }

  addLineItemToInvoice(data){
    $('#line-builder-modal').modal('hide');

    var lastRow = $('.line-item-row:visible:last');

    if(window.lineItemEditClass !== undefined && window.lineItemEditClass !== '' && $(window.lineItemEditClass).is('*')){
      var lineItemRow = $(window.lineItemEditClass);
      window.lineItemEditClass = '';
    } else {
      $('#js-add-line-item').click();
      var lineItemRow = $('.line-item-row:visible:last');
    }

    var itemType = data.type;
    var itemID = data.id;

    var itemTypeField = lineItemRow.find('.js-item-type');
    var addonIdField = lineItemRow.find('.js-addon-id');
    var serviceIdField = lineItemRow.find('.js-service-id');
    var seriesIdField = lineItemRow.find('.js-series-id');
    var packageIdField = lineItemRow.find('.js-package-id');
    var productIdField = lineItemRow.find('.js-product-id');
    var variantIdField = lineItemRow.find('.js-variant-id');
    var inventoryIdField = lineItemRow.find('.js-inventory-id');
    var inventoryInstockInfoField = lineItemRow.find('.js-inventory-stock-info')

    if(data.htmltitle != undefined) {
      var typeTitle = data.htmltitle;
    } else {
      var typeTitle = data.title;
    }

    lineItemRow.data(data);
    lineItemRow.find('.type-title').html(typeTitle);
    lineItemRow.find('.title-field').val(data.description);
    lineItemRow.find('.unit-price').val(data.price);

    window.data = data;

    if(data.stockbadgeicon !== undefined && data.stockbadgeicon != ''){
      inventoryInstockInfoField.html(data.stockbadgeicon );
    } else {
      inventoryInstockInfoField.html('');
    }

    itemTypeField.val(itemType);

    addonIdField.val('');
    serviceIdField.val('');
    seriesIdField.val('');
    packageIdField.val('');
    productIdField.val('');
    variantIdField.val('');
    inventoryIdField.val('');


    switch(itemType) {
      case 'addon':
        addonIdField.val(itemID);
        break;
      case 'service':
        serviceIdField.val(itemID);
        break;
      case 'series':
        seriesIdField.val(itemID);
        break;
      case 'package':
        packageIdField.val(itemID);
        break;
      case 'product':
        productIdField.val(itemID);
        inventoryIdField.val(data.inventoryid);
        break;
      case 'variant':
        variantIdField.val(itemID);
        inventoryIdField.val(data.inventoryid);
        break;
    }

    // Update the totals with another JS callback
    lineItemRow.trigger('change');

    // Ensure tooltips are re-init.
    $('[data-toggle="tooltip"]').tooltip();
  }

  selectVariantRow(event){
    var select = $(event.target);
    var selected = select.val()
    var parentElem = select.closest('.ob-service-button');

    parentElem.find('.js-variant-row').hide();
    parentElem.find('.js-variant-row[data-item-id="'+ selected + '"]').show();

    event.preventDefault();
  }

  search(query) {
    var controller = this;
    if(window.currentTypheadQuery != query){
      return;
    }

    window.currentTypheadQuery = query + "[completed]";

    var form = $('#line-item-typeahead-search-form');
    var url = form.attr('action');
    var data = form.serialize();

    $.ajax({
      type: "POST",
      url: url,
      data: data,
      dataType: 'script',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"))
      },
      success: function(response) {
        $('#line-item-builder-items').html(response);
        $('.selectpicker').selectpicker('refresh');
        controller.setLoader(false);
      },
      error: function(response) {
        console.log("Failed typhead search", response);
      },
    });
  }

  searchInputChange(event) {
    var query = $('#line-item-search-input').val();
    var controller = this;
    window.currentTypheadQuery = query;

    if(query.length > 0){
      $('#clear-line-item-search').show();
    } else {
      $('#clear-line-item-search').hide();
    }

    controller.setLoader(true);
    setTimeout(() => {
      controller.search(query);
    }, 500);

    event.preventDefault();
  }

  clearSearch(event) {
    var query = '';
    var controller = this;
    window.currentTypheadQuery = query;

    $('#line-item-search-input').val(query);
    $('#clear-line-item-search').hide();

    controller.setLoader(true);
    setTimeout(() => {
      controller.search(query);
    }, 500);

    event.preventDefault();
  }

  setLoader(loaderIsOn) {
    if(loaderIsOn) {
      $('#line-item-builder-items-loader').show();
      $('#line-item-builder-items').hide();
    } else {
      $('#line-item-builder-items-loader').hide();
      $('#line-item-builder-items').show();

    }
  }

}