import Calendar, { MonthGuide } from "tui-calendar"; /* ES6 */
import "tui-calendar/dist/tui-calendar.css";

// If you use the default popups, use this.
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";

onmount = require("onmount");
var moment = require("moment-timezone");
var lodash = require("lodash");

import { isControlKey } from "./is_control_key";

// Consider this to be an 'event' in a calendar
//
// Borrowed from the example code:
// https://github.com/nhn/tui.calendar/blob/27d6d345fd8b47ebc66a75e001fe39aa810942e7/examples/js/data/schedules.js#L12-L53
// function ScheduleInfo() {
//   this.id = null;
//   this.calendarId = null;
//
//   this.title = null;
//   this.body = null;
//   this.isAllday = false;
//   this.start = null;
//   this.end = null;
//   this.category = "";
//   this.dueDateClass = "";
//
//   this.color = null;
//   this.bgColor = null;
//   this.dragBgColor = null;
//   this.borderColor = null;
//   this.customStyle = "";
//
//   this.isFocused = false;
//   this.isPending = false;
//   this.isVisible = true;
//   this.isReadOnly = false;
//   this.goingDuration = 0;
//   this.comingDuration = 0;
//   this.recurrenceRule = "";
//   this.state = "";
//
//   this.raw = {
//     memo: "",
//     hasToOrCc: false,
//     hasRecurrenceRule: false,
//     location: null,
//     class: "public", // or 'private'
//     creator: {
//       name: "",
//       avatar: "",
//       company: "",
//       email: "",
//       phone: "",
//     },
//   };
// }

// Example GET method implementation:
async function getData(url = "", data = {}) {
  url = url + "?" + new URLSearchParams(data);

  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

var setVisibleSchedules = function (calendar, schedules, filterKeyValuePairs) {
  if (lodash.isEqual(filterKeyValuePairs, {})) {
    for (var i = 0, len = schedules.length; i < len; i++) {
      schedules[i].isVisible = true;
    }
    calendar.clear();
    calendar.createSchedules(schedules);
    return;
  }

  var targetKeys = {};
  for (const [key, value] of Object.entries(filterKeyValuePairs)) {
    if (!targetKeys[value.filter_criteria_class])
      targetKeys[value.filter_criteria_class] = [];

    targetKeys[value.filter_criteria_class].push(key);
  }

  for (var i = 0, len = schedules.length; i < len; i++) {
    const result = lodash.every(
      Object.keys(targetKeys),
      (filter_criteria_class) => {
        return lodash.some(targetKeys[filter_criteria_class], (val) => {
          var tags = schedules[i].raw.tags;
          if (tags === undefined) {
            // Used to display things we want to display regardless like availability exceptions
            return schedules[i];
          } else {
            return tags.indexOf(val) !== -1;
          }
        });
      }
    );

    schedules[i].isVisible = result;
  }

  calendar.clear();
  calendar.createSchedules(schedules);
};

var tui_schedules = [];
var calendar;
var refresher;
var popover_guide;

var refreshCalendar = function (calendar, start_at, end_at) {
  var events_url = $("#tui-calendar").data("url");
  $(".js-hide-me-after-loading").animate({ opacity: 1.0 }, 1000);
  getData(events_url, {
    start: start_at,
    end: end_at,
  }).then(
    (server_data) => {
      if (calendar._controller) {
        upsertEventsIntoCalendar(calendar, server_data);
      }
      $(".js-hide-me-after-loading").animate({ opacity: 0.0 }, 500);
    },
    (server_data) => {
      window.notyf.error(
        "Sorry, something went wrong. The calendar failed to refresh some dates."
      );
    }
  );
};

var refreshCalendarBasedOnVisibleRange = function (calendar) {
  var extract_date = function (tz_date) {
    return moment([
      tz_date.getFullYear(),
      tz_date.getMonth(),
      tz_date.getDate(),
    ]);
  };

  // This persists the user's choice
  getData("/business/calendars/persist_calendar_intent", {
    calendar_last_date_intent: currentCalendarDate(calendar, "YYYY-MM-DD"),
  }).then((_data) => {});

  refreshCalendar(
    calendar,
    extract_date(calendar.getDateRangeStart()).toISOString(),
    extract_date(calendar.getDateRangeEnd()).add(1, "day").toISOString()
  );
};

var upsertEventsIntoCalendar = function (calendar, server_data) {
  //  calendar.clear(); -- don't do this anymore - we'd rather have the data
  //  for a date range instead of pre-emptively clearing the calendar +- 3
  //  months and substituting in the server data. We have been dealing with
  //  some issues where the calendar clears before it's loaded, and this is
  //  very evident when using the week or day view when the user clicks through
  //  a lot of days/weeks to get to their desired date and we've fired X
  //  requests.
  //
  //  We also need to deal with the fact that an event may be deleted.
  //  Speaking of which...  TODO: deal with events no longer present in a
  //  date range that we've just refreshed.

  // This represents the latest data from the server - represented as a object/hash
  var server_map = lodash.fromPairs(server_data.map((item) => [item.id, item]));

  // This is our local data - represented as a object/hash that is keyed by the id field.
  var local_map = lodash.fromPairs(
    tui_schedules.map((item) => [item.id, item])
  );

  // This overwrites local_map with server_map keys/values and merges new keys
  // in TODO: fix bug where an AvailabilityException.id value from a record and
  // a booking's id from a record being the same value breaks this logic and
  // only allows one to exist.
  let merged = { ...local_map, ...server_map };
  tui_schedules = lodash.values(merged);

  applyFiltersToCalendar(calendar, tui_schedules);
};

var currentCalendarDate = (cal, format) => {
  var currentDate = moment([
    cal.getDate().getFullYear(),
    cal.getDate().getMonth(),
    cal.getDate().getDate(),
  ]);

  return currentDate.format(format);
};

var setRenderRangeText = (cal) => {
  var output = document.querySelector(".js-calendar-title");
  var options = cal.getOptions();
  var viewName = cal.getViewName();

  var html = [];
  if (viewName === "day") {
    html.push(currentCalendarDate(cal, "MMMM YYYY"));
  } else if (
    viewName === "month" &&
    (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)
  ) {
    html.push(currentCalendarDate(cal, "MMMM YYYY"));
  } else {
    html.push(currentCalendarDate(cal, "MMMM YYYY"));
    // html.push(moment(cal.getDateRangeStart().getTime()).format("YYYY.MM.DD"));
    // html.push(" to ");
    // html.push(moment(cal.getDateRangeEnd().getTime()).format(" MM.DD"));
  }
  output.innerHTML = html.join("");
};

// This function reads each of the Location-User/Client-Server
// filters we have on the home calendar screen when the user logs
// in. We do this by getting the tags/filter-keys-to-filter-for
// (`filterKeys` below).
//
// That data is then used in
// setVisibleSchedules to hide or show an event in the calendar if
// the event's tags match a tag from the filterKeys lookup hash.
var applyFiltersToCalendar = (calendar, tui_schedules) => {
  var filterKeys = {};

  const data_source_html_elements = document.querySelectorAll(
    "select.js-calendar-filterable-data-source"
  );
  for (var i = 0, len = data_source_html_elements.length; i < len; i++) {
    for (let enabledOption of data_source_html_elements[i].selectedOptions) {
      let k = enabledOption.dataset.k;
      let v = enabledOption.dataset.v;
      let filter_criteria_class = enabledOption.dataset.filtercriteriaclass;

      filterKeys[k + "|" + v] = {
        key: k,
        value: v,
        filter_criteria_class: filter_criteria_class,
      };
    }
  }

  setVisibleSchedules(calendar, tui_schedules, filterKeys);
  onmount();
  displayFilterCount();
};

/**
 * Get time template for time and all-day
 * @param {Schedule} schedule - schedule
 * @param {boolean} isAllDay - isAllDay or hasMultiDates
 * @returns {string}
 */
function getTimeTemplate(schedule, isAllDay) {
  var html = [];

  if(schedule.raw.humanStart !== undefined) {
    var start = schedule.raw.humanStart;
  } else {
    var start = moment(schedule.start.toUTCString()).format("h:mma");
  }

  if (!isAllDay) {
    html.push("<strong>" + start + "</strong> ");
  }
  if (schedule.isPrivate) {
    html.push('<span class="calendar-font-icon ic-lock-b"></span>');
    html.push(" Private");
  } else {
    if (schedule.isReadOnly) {
      html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
    } else if (schedule.recurrenceRule) {
      html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
    } else if (schedule.attendees.length) {
      html.push('<span class="calendar-font-icon ic-user-b"></span>');
    } else if (schedule.location) {
      html.push('<span class="calendar-font-icon ic-location-b"></span>');
    }

    if (schedule.raw.bookingType == "group") {
      html.push('<i class="fas fa-users mx-1"></i>');
    } else if (schedule.raw.bookingType == "series") {
      html.push('<i class="fas fa-chevron-double-right mx-1"></i>');
    } else {
      html.push('<i class="fas fa-user mx-1"></i>');
    }

    html.push(" " + schedule.title);
  }

  return html.join("");
}

onmount(
  ".js-tui-calendar",
  function () {
    var start_hour = parseInt(this.dataset.calendarStartHourInDayAndWeekView);
    var end_hour = parseInt(this.dataset.calendarEndHourInDayAndWeekView);

    end_hour = Math.min(...[end_hour, 24]);
    end_hour = Math.max(...[end_hour, 0]);

    start_hour = Math.min(...[start_hour, 24]);
    start_hour = Math.max(...[start_hour, 0]);

    var forced_to_use_day_view = false;

    if (window.innerWidth < 600) {
      var default_view = "day";
      forced_to_use_day_view = true;
    } else {
      var default_view = this.dataset.calendarDefaultView;
    }

    calendar = new Calendar(".js-tui-calendar", {
      defaultView: default_view,
      week: {
        startDayOfWeek: 1,
        hourStart: start_hour,
        hourEnd: end_hour,
      },
      month: {
        startDayOfWeek: 0,
      },
      taskView: false,
      isReadOnly: false,
      useDetailPopup: false,
      template: {
        time: function (schedule) {
          return getTimeTemplate(schedule, false);
        },
      },
      timezone: {
        zones: [
          {
            timezoneName: this.dataset.jsCalendarTzinfoIdentifier,
            displayLabel: this.dataset.jsCalendarTzinfoIdentifier,
            tooltip: this.dataset.jsCalendarTzinfoIdentifier,
          },
        ],
        offsetCalculator: function (timezoneName, timestamp) {
          // matches 'getTimezoneOffset()' of Date API
          // e.g. +09:00 => -540, -04:00 => 240
          return moment.tz.zone(timezoneName).utcOffset(timestamp);
        },
      },
    });

    var start_date = this.dataset.calendarLastDateIntent;

    if (start_date) {
      var year = parseInt(start_date.substring(0, 4));
      var month = parseInt(start_date.substring(5, 7));
      var day = parseInt(start_date.substring(8, 10));
      var desired_date = new Date(year, month - 1, day, 12); // 12 = noon and month is 0 indexed
      calendar.setDate(desired_date);
    }

    var lastClickSchedule = null;
    calendar.on({
      clickSchedule: function (event) {
        // console.log("clickSchedule event", event);
        var schedule = event.schedule;

        if (!schedule.raw || !schedule.raw.visitable_url) {
          return;
        }

        // focus the schedule
        if (lastClickSchedule) {
          calendar.updateSchedule(
            lastClickSchedule.id,
            lastClickSchedule.calendarId,
            {
              color: "#fff",
              isFocused: false,
            }
          );
        }
        calendar.updateSchedule(schedule.id, schedule.calendarId, {
          color: "#cb00ff",
          isFocused: true,
        });

        // Set our lastClickSchedule to the new one
        lastClickSchedule = schedule;

        // open detail view
        window.location.href = schedule.raw.visitable_url;
      },
      beforeUpdateSchedule: function (event) {
        // console.log("beforeUpdateSchedule event", event);
      },
      beforeCreateSchedule: function (event) {
        var start_at_date_param = moment(event.start.toDate())
          .toISOString()
          .replace(/T.*/g, "");

        var diff = Math.abs(event.end.toDate() - event.start.toDate());
        var start_at_time = moment(event.start.toDate()).format("h:mm A");

        // you can change the text (maybe) by setting .title below:
        // event.guide.guideElement.title = "New booking";

        if (event.guide.guideElements) {
          setTimeout(() => {
            event.guide.clear();
          }, 1000);

          // we're in month view - let's drill into the selected date
          calendar.setDate(event.start);
          setView("day");
        } else if (event.guide.guideElement) {
          event.guide.guideElement.dataset.content = `<a href='/business/bookings/new?start_at_date=${start_at_date_param}&start_at_time=${start_at_time}' class='btn btn-tertiary'><i class="fa fa-plus-circle"></i>&nbsp;Add booking</a>`;
          popover_guide = event.guide.guideElement;
          $(event.guide.guideElement).popover({
            placement: "left",
            html: true,
          });
          $(event.guide.guideElement).popover("show");
        } else {
          // TODO: send HB/LogRocket notification
          console.log(
            "We're not able to spawn a 'Add Booking' button or go to the day in question"
          );
        }
      },
    });

    refreshCalendarBasedOnVisibleRange(calendar);

    var refreshCalendarPeriodically = function () {
      return false;
      if ($("#tui-calendar").is("*")) {
        if (document.hidden === false) {
          refreshCalendarBasedOnVisibleRange(calendar);
        }

        refresher = setTimeout(refreshCalendarPeriodically, 10000);
      }
    };

    refresher = setTimeout(refreshCalendarPeriodically, 10000);

    $(document).one(
      "turbolinks:before-cache turbolinks:before-render",
      function () {
        clearTimeout(refresher);

        // This fixes an issue where when we use the back button to return then
        // this is an empty div that is the size of the entire calendar. So, we
        // have this callback in the before-cache call to remove it before we
        // store the turbolinks cache. When we return, it's gone and the page
        // functions normally afaict.
        $(".tui-full-calendar-layout").remove();
      }
    );

    refreshCalendar(
      calendar,
      this.dataset.calendarStartAt,
      moment([
        calendar.getDate().getFullYear(),
        calendar.getDate().getMonth(),
        calendar.getDate().getDate(),
      ])
        .add(4, "months")
        .toISOString()
    );

    setRenderRangeText(calendar);

    // Cancel+reset calls to rerender to 200ms if resizing continues.
    var debounceRender = lodash.debounce(() => {
      calendar.render();
    }, 200);

    var originalBeforeResized = null;

    var resizeCalendarFunction = () => {
      if (originalBeforeResized == null && window.innerWidth < 600) {
        originalBeforeResized = calendar.getViewName();
        setView("day");
      } else if (window.innerWidth >= 600 && originalBeforeResized != null) {
        // Reset the view to what it was before we forced the view to 'Day' a few lines above
        setView(originalBeforeResized);
        originalBeforeResized = null;
      }

      debounceRender();
    };

    var setView = function (typeOfView) {
      if (popover_guide) {
        $(popover_guide).popover("hide");
      }

      var typeOfViewDisplayValue = { day: "Day", month: "Month", week: "Week" }[
        typeOfView
      ];

      var item = $(".js-calendar-intent-replace-me")[0];
      if (!item) {
        return;
      }

      item.innerHTML = typeOfViewDisplayValue;
      $(".js-calendar-intent-click.active").removeClass("active");
      var selector =
        '.js-calendar-intent-click[data-calendar-intent-value="' +
        typeOfView +
        '"]';
      $(selector).addClass("active");
      calendar.changeView(typeOfView);
      setRenderRangeText(calendar);
    };

    // Render a calendar when resizing a window.
    window.addEventListener("resize", resizeCalendarFunction);
    if (forced_to_use_day_view) {
      setView("day");
    }

    onmount(".js-calendar-date-increment-or-decrement", function () {
      $(this).unbind("click");
      $(this).on("click", () => {
        const sign = this.dataset.calendarSignValue;

        var currentDate = moment([
          calendar.getDate().getFullYear(),
          calendar.getDate().getMonth(),
          calendar.getDate().getDate(),
        ]);

        switch (sign) {
          case "1":
            calendar.next();
            break;
          case "-1":
            calendar.prev();
            break;
        }
        setRenderRangeText(calendar);

        refreshCalendarBasedOnVisibleRange(calendar);
      });
    });

    onmount(".js-calendar-intent-click", function () {
      $(this).on("click", () => {
        const value = this.dataset.calendarIntentValue;
        const display_value = this.dataset.calendarIntentDisplayValue;

        setView(value, display_value);
        refreshCalendarBasedOnVisibleRange(calendar);

        // This persists the user's choice
        getData("/business/calendars/persist_calendar_intent", {
          calendar_intent: value,
        }).then((_data) => {});
      });
    });

    onmount(".js-calendar-go-to-today", function () {
      $(this).on("click", () => {
        calendar.today();
        refreshCalendarBasedOnVisibleRange(calendar);
        setRenderRangeText(calendar);
      });
    });

    onmount("#js-calendar-filter-button", function() {
      $(this).on('click', function () {
        if($('#js-calendar-filters').is(':visible')) {
          $('#js-calendar-filters').slideUp('fast');
        } else {
          $('#js-calendar-filters').slideDown('fast');
        }
      })
    });

    onmount("#js-calendar-filter-by-person", function () {
      $(this)
        .selectpicker({
          actionsBox: true,
          liveSearch: true,
          liveSearchPlaceholder: "Filter by Person/Pet",
        })
        .ajaxSelectPicker({
          locale: { currentlySelected: "" },
          ajax: {
            url: "/business/search/person",
            beforeSend: function (xhr) {
              xhr.setRequestHeader(
                "X-CSRF-Token",
                localStorage.getItem("bp-x-csrf-token")
              );
            },
            data: function () {
              var params = {
                q: "{{{q}}}",
              };
              // This is how to add more data to params if you so desire. In the
              // example the GroupID parameter is added so the GET request will be similar to
              // ?q=<q>&GroupID=<assigned-value>
              //
              //if (gModel.selectedGroup().hasOwnProperty("ContactGroupID")) {
              //  params.GroupID = gModel.selectedGroup().ContactGroupID;
              //}
              return params;
            },
          },
          clearOnEmpty: true,
          preserveSelectedPosition: "before",
          emptyRequest: true,
          cache: false,
          preserveSelected: true,
          //preprocessData: function (data) {
          //  return data;
          //},
        });

      var htmlToRestore = window.localStorage.getItem(
        "js-calendar-filter-by-person-container-inner-html"
      );

      if (htmlToRestore !== null) {
        $("#js-calendar-filter-by-person").html(htmlToRestore);
        $("#js-calendar-filter-by-person").selectpicker("refresh");
      }

      // Fixes an issue where we want to display an initial list of options but
      // the current behaviour of AjaxBootstrapSelect with preserveSelected set
      // to true is to on keyup after typing into the live search show that
      // initial list as 'currently selected'.
      //
      // This is visually incorrect since the user did not click on any of the
      // initial list of options.
      //
      // See this issue that I reported:
      //
      // https://github.com/truckingsim/Ajax-Bootstrap-Select/issues/199
      $(
        ".js-calendar-filter-by-person-container .bootstrap-select input"
      )[0]?.addEventListener(
        "keydown",
        (event) => {
          if (isControlKey(event)) return;
          // bootstrap-select: remove options and then invoke selectpicker("refresh") on
          // the next line - these are the standard instructions from the
          // bootstrap-select lib.
          //
          // Also, I think I may be a little bit aggressive here in removing
          // options against options that may actually be selected, however,
          // nothing visually or otherwise indicates a problem with this code as
          // it is and the effect of this code is limited to only one invocation,
          // so I'm comfortable with this as is.
          //
          // Versions:
          //  "ajax-bootstrap-select": "^1.4.5",
          //  "bootstrap-select": "^1.13.18",
          $(
            '.js-calendar-filter-by-person-container [data-remove-preset="true"]'
          ).remove();
          $(
            ".js-calendar-filter-by-person-container .selectpicker"
          ).selectpicker("refresh"); // bootstrap-select: list is visually removed from UI at this point
          var actually_selected_for_us_to_keep = _.filter(
            $(".js-calendar-filter-by-person-container .selectpicker").data(
              "AjaxBootstrapSelect"
            ).list.selected,
            (item) => {
              return item.selected;
            }
          );

          $(".js-calendar-filter-by-person-container .selectpicker").data(
            "AjaxBootstrapSelect"
          ).list.selected = actually_selected_for_us_to_keep; // Ajax-Bootstrap-Select: this line is needed so that the list is not restored into the `Currently Selected` optgroup upon keydown
        },
        { once: true }
      );

      $(this).on("change", function () {
        // Store the last used <optgroup><option> html so we can pre-populate to exact same state when they return back.
        if ($("#js-calendar-filter-by-person option:selected").length > 0) {
          window.localStorage.setItem(
            "js-calendar-filter-by-person-container-inner-html",
            $("#js-calendar-filter-by-person").html()
          );
        } else {
          window.localStorage.removeItem(
            "js-calendar-filter-by-person-container-inner-html"
          );
        }

        applyFiltersToCalendar(calendar, tui_schedules);
      });
    });

    onmount("#js-calendar-filter-by-service", function () {
      var htmlToRestore = window.localStorage.getItem(
        "js-calendar-filter-by-service-container-inner-html"
      );

      if (htmlToRestore !== null) {
        $("#js-calendar-filter-by-service").html(htmlToRestore);
        $("#js-calendar-filter-by-service").selectpicker("refresh");
      }

      $(this).on("change", function () {
        // Store the last used <optgroup><option> html so we can pre-populate to exact same state when they return back.
        if ($("#js-calendar-filter-by-service option:selected").length > 0) {
          $("#js-calendar-filter-by-service option:selected").each(function (
            _index
          ) {
            $(this).attr("selected", "selected");
          });
          window.localStorage.setItem(
            "js-calendar-filter-by-service-container-inner-html",
            $("#js-calendar-filter-by-service").html()
          );
        } else {
          $("#js-calendar-filter-by-service option").each(function (_index) {
            $(this).removeAttr("selected");
          });
          window.localStorage.removeItem(
            "js-calendar-filter-by-service-container-inner-html"
          );
        }

        applyFiltersToCalendar(calendar, tui_schedules);
      });
    });

    onmount("#js-calendar-filter-by-location", function () {
      var htmlToRestore = window.localStorage.getItem(
        "js-calendar-filter-by-location-container-inner-html"
      );

      if (htmlToRestore !== null) {
        $("#js-calendar-filter-by-location").html(htmlToRestore);
        $("#js-calendar-filter-by-location").selectpicker("refresh");
      }

      $(this).on("change", function () {
        // Store the last used <optgroup><option> html so we can pre-populate to exact same state when they return back.
        if ($("#js-calendar-filter-by-location option:selected").length > 0) {
          $("#js-calendar-filter-by-location option:selected").each(function (
            _index
          ) {
            $(this).attr("selected", "selected");
          });

          window.localStorage.setItem(
            "js-calendar-filter-by-location-container-inner-html",
            $("#js-calendar-filter-by-location").html()
          );
        } else {
          $("#js-calendar-filter-by-location option").each(function (_index) {
            $(this).removeAttr("selected");
          });
          window.localStorage.removeItem(
            "js-calendar-filter-by-location-container-inner-html"
          );
        }

        applyFiltersToCalendar(calendar, tui_schedules);
      });
    });


    onmount("#js-calendar-filter-by-statuses", function () {
      var htmlToRestore = window.localStorage.getItem(
        "js-calendar-filter-by-statuses-container-inner-html"
      );

      if (htmlToRestore !== null) {
        $("#js-calendar-filter-by-statuses").html(htmlToRestore);
        $("#js-calendar-filter-by-statuses").selectpicker("refresh");
      }

      $(this).on("change", function () {
        // Store the last used <optgroup><option> html so we can pre-populate to exact same state when they return back.
        if ($("#js-calendar-filter-by-statuses option:selected").length > 0) {
          $("#js-calendar-filter-by-statuses option:selected").each(function (
            _index
          ) {
            $(this).attr("selected", "selected");
          });
          window.localStorage.setItem(
            "js-calendar-filter-by-statuses-container-inner-html",
            $("#js-calendar-filter-by-statuses").html()
          );
        } else {
          $("#js-calendar-filter-by-statuses option").each(function (_index) {
            $(this).removeAttr("selected");
          });
          window.localStorage.removeItem(
            "js-calendar-filter-by-statuses-container-inner-html"
          );
        }

        applyFiltersToCalendar(calendar, tui_schedules);
      });
    });
  },
  function () {
    clearTimeout(refresher);
    calendar.destroy();
    $(".tui-full-calendar-layout").remove();
  }
);

function countAppliedFilters() {
  const count = $("#js-calendar-filter-by-statuses option:selected").length + $("#js-calendar-filter-by-location option:selected").length + $("#js-calendar-filter-by-service option:selected").length + $("#js-calendar-filter-by-person option:selected").length;
  return count;
}

function displayFilterCount() {
  if(countAppliedFilters() > 0) {
    $('#js-applied-filter-count').html(countAppliedFilters() + ' filter(s) applied.');
  } else {
    $('#js-applied-filter-count').empty();
  }
}
