onmount("#recurring-btn", function () {
  $(this).on("click", (e) => {
    e.preventDefault();
    e.stopPropagation();
    $('#cancel-recurring').removeClass('active');
    $("#recurring-btn").addClass('active');
    $("#recurring-booking input").prop("disabled", false);
    $("#recurring-booking").slideDown("fast");
  });
});

onmount(".js-booking-frequency", function () {
  $(this).on("change", (e) => {
    console.log(".js-booking-frequency select is changing");
    e.preventDefault();
    e.stopPropagation();
    var frequency = $(e.target).val();

    if (frequency === "Monthly" || frequency === "Daily") {
      $("#frequency-repeats-on").hide();
    } else {
      $("#frequency-repeats-on").show();
    }
  });
});

onmount(".js-toggler-show-target", function () {
  $(this).on("change", function () {
    console.log("ran .js-toggler-show-target");
    var targetElem = $($(this).data("showTarget"));
    targetElem.show();
  });
});

onmount(".js-toggler-hide-target", function () {
  $(this).on("change", function () {
    console.log("ran .js-toggler-hide-target");
    var targetElem = $($(this).data("hideTarget"));
    targetElem.hide();
  });
});

onmount("#cancel-recurring", function () {
  $(this).on("click", (e) => {
    e.preventDefault();
    e.stopPropagation();
    $('#cancel-recurring').addClass('active');
    $("#recurring-btn").removeClass('active');
    $("#recurring-booking").slideUp("fast");
    $("#recurring-booking input").prop("disabled", true);
  });
});
