$(document).on("turbolinks:load", function () {
  if ($('#js-payment-type-buttons').is('*')) {

    $('#js-payment-type-buttons label').on('click', function() {
      var amountField = $('#js-amount-input-field');
      var invoiceMaxValue = amountField.data('max');

      if($(this).attr('id') != 'js-credit-card-type'){
        $('#js-client-payment-methods').slideUp('fast', function(){
          $('#js-client-payment-methods input').prop('disabled', true);
        });
      } else {
        $('#js-client-payment-methods input').prop('disabled', false);
        $('#js-client-payment-methods').slideDown('fast');
      }

      if( $(this).attr('id') == 'js-cash-credit-type' ) {
        var creditMaxValue = $(this).data('max');
        $('#js-cash-credit-balance').slideDown('fast');

        if (parseFloat(creditMaxValue) < parseFloat(invoiceMaxValue)) {
          amountField.prop('max', creditMaxValue);
          amountField.val(creditMaxValue);
        }

      } else {
        $('#js-cash-credit-balance').slideUp('fast');
        amountField.prop('max', invoiceMaxValue);
      }

    });

  }
});
